import React, { useState } from 'react'
import {
  SceneIntroWrapper,
  ContentWrapper,
  Content,
  SwitchWrapper,
  ItemDetail,
} from './index.style'
import RightIcon from './images/rightIcon.png'
import CardBg from './images/cardBg.png'
import LeftArrow from './images/LeftArrow'
import RightArrow from './images/RightArrow'
import WorkImg from '../../../images/home/sceneIntro/work.webp'
import DesignImg from '../../../images/home/sceneIntro/design.webp'
import TechnologyImg from '../../../images/home/sceneIntro/technology.webp'
import GameImg from '../../../images/home/sceneIntro/game.webp'
import DevOpsImg from '../../../images/home/sceneIntro/devops.webp'

const SceneIntro = () => {
  const pageSize = 3
  const data = [
    {
      name: '安全办公',
      desc: '企业快速部署，员工办公提效',
      points: [
        '配置角色权限，满足不同岗位需求',
        '连接稳定有保障，办公更舒心',
        '远控前、中、后部署多种安全措施',
      ],
      img: WorkImg,
    },
    {
      name: '设计制作',
      desc: '专业赋能创意，产出更加高效',
      points: [
        '超高清，低延迟，4:4:4真色彩显示',
        '数位板操控流畅，精准灵敏响应',
        '权限可控，避免公司方案文件外泄',
      ],
      img: DesignImg,
    },
    {
      name: '技术支持',
      desc: '优化远协体验，责任管理到人',
      points: [
        '受控支持绿色版免安装运行',
        '记录远控日志，员工行为留痕',
        '操作加密录屏，事后有效追溯',
      ],
      img: TechnologyImg,
    },
    {
      name: '游戏娱乐',
      desc: '超流畅高性能，更多花样玩法',
      points: [
        '支持电竞级144帧',
        '手机电脑互控，随时随地畅玩',
        '适配PS、Xbox游戏手柄',
      ],
      img: GameImg,
    },
    {
      name: '运维排障',
      desc: '快速响应问题，助力业务升级',
      points: [
        '快速远程排查故障，降低损失',
        '减少沟通成本，提升服务效率',
        '有效管理成员及设备，权责分明',
      ],
      img: DevOpsImg,
    },
  ]
  const [activeIndex, setActiveIndex] = useState(0)
  const handleSwitch = type => {
    const pageNum = data.length
    if (type === 'left') {
      if (activeIndex === 0) return
      setActiveIndex(pre => (pre - 1 + pageNum) % pageNum)
    } else {
      if (activeIndex === pageNum - pageSize) return
      setActiveIndex(pre => (pre + 1) % pageNum)
    }
  }
  return (
    <SceneIntroWrapper>
      <h2>多元场景，轻松覆盖</h2>
      <ContentWrapper>
        <Content activeIndex={activeIndex}>
          {data.map((item, index) => (
            <div className="content-item" key={index}>
              <img
                className="img"
                src={item.img}
                alt={item.name}
                loading="lazy"
              />
              <div className="desc-info">
                <div className="blur" />
                <div className="text">
                  <h3>{item.name}</h3>
                  <p>{item.desc}</p>
                  <ItemDetail>
                    <div className="detail-item">
                      <div className="line" />
                      <ul>
                        {item.points.map((point, index) => (
                          <li key={index}>
                            <img src={RightIcon} alt="✅" loading="lazy" />
                            <span>{point}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <img
                      className="card-bg"
                      src={CardBg}
                      loading="lazy"
                      alt=""
                    />
                  </ItemDetail>
                </div>
              </div>
            </div>
          ))}
        </Content>
      </ContentWrapper>
      <div className="bottom-switch">
        <SwitchWrapper
          className={`${activeIndex === 0 ? 'disabled' : ''}`}
          onClick={() => handleSwitch('left')}
        >
          <LeftArrow />
        </SwitchWrapper>
        <SwitchWrapper
          className={`${
            activeIndex === data.length - pageSize ? 'disabled' : ''
          }`}
          onClick={() => handleSwitch('right')}
        >
          <RightArrow />
        </SwitchWrapper>
      </div>
    </SceneIntroWrapper>
  )
}

export default SceneIntro
